// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"src/components/react-use-websocket/globals.ts"
);
import.meta.hot.lastModified = "1735653278561.833";
}
// REMIX HMR END

import { WebSocketLike } from "./types";

export interface SharedWebSockets {
  [url: string]: WebSocketLike;
}

export const sharedWebSockets: SharedWebSockets = {};

export const resetWebSockets = (url?: string): void => {
  if (url && sharedWebSockets.hasOwnProperty(url)) {
    delete sharedWebSockets[url];
  } else {
    for (let url in sharedWebSockets){
      if (sharedWebSockets.hasOwnProperty(url)){
        delete sharedWebSockets[url];
      }
    }
  }
}
